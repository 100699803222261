













































































































































@import '@design';

.menu-layout {
  &.isDirty {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: purple;
      transform: translateY(-200%);
    }
  }
}

.menu-layout--title {
  margin-bottom: 0.3em;
  font-size: 130px;
  font-weight: 500;
  line-height: 0.65;

  @media screen and (min-width: 960px) {
    margin-bottom: 0.4em;
    font-size: 180px;
  }
}

.menu-sections {
  // TODO (ESS) : don't hardcode this.
  // this is to account for the px-3 applied on MenuSecdtion
  margin-right: -16px;
  margin-left: -16px;
}
