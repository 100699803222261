





















































@import '@design';

.tagline {
  font-size: 3rem;
}

.green-hr {
  height: 1px;
  background: #2e7339;
}

.blurb {
  margin-top: 8vw;
}

.blurb--title {
  font-size: 0.9rem;
  border-bottom: 2px solid $color-db-purple;
}
.blurb--desc {
  font-size: 0.9rem;
  line-height: 1.7;
}
