@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
.menu-legend{font-size:.75rem;border:1px solid #ccc;border-radius:6px}.menu-legend .menu-section--items{margin:1em 0}.menu-legend .callout-title--icon{height:48px;margin:-3px 12px -3px 0}@media(min-width: 600px){.menu-legend .menu-legend--attr{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto}}@media(min-width: 1200px){.menu-legend .menu-legend--attr{max-width:250px}}
.tagline{font-size:3rem}.green-hr{height:1px;background:#2e7339}.blurb{margin-top:8vw}.blurb--title{font-size:.9rem;border-bottom:2px solid #940152}.blurb--desc{font-size:.9rem;line-height:1.7}
.callout-item .menu-item--prices{font-weight:normal !important;text-align:left !important}
.menu-item.condensed{position:relative}.menu-item.condensed .item-price{font-weight:normal}
.menu-section--title[data-v-db782a38]{font-size:60px;line-height:.7}.menu-section.callout[data-v-db782a38]{max-width:300px;margin-right:auto;margin-left:auto;border:4px solid #940152;border-radius:6px}.menu-section.callout .menu-section--items[data-v-db782a38]{margin:1em 0}.menu-section.callout .callout-title--icon[data-v-db782a38]{height:48px;margin:-3px 12px -3px 0}
.menu-layout.isDirty{position:relative}.menu-layout.isDirty::after{position:absolute;top:0;left:0;display:block;width:100%;height:5px;content:"";background:purple;-webkit-transform:translateY(-200%);transform:translateY(-200%)}.menu-layout--title{margin-bottom:.3em;font-size:130px;font-weight:500;line-height:.65}@media screen and (min-width: 960px){.menu-layout--title{margin-bottom:.4em;font-size:180px}}.menu-sections{margin-right:-16px;margin-left:-16px}

/*# sourceMappingURL=chunk-6bc5e14f.9cdb7cb7.css.map*/